// extracted by mini-css-extract-plugin
export var ArtistDescription = "EddieMartinez-module--ArtistDescription--7eI8g";
export var ArtistInfos = "EddieMartinez-module--ArtistInfos--4qpjS";
export var ButtonWrapper = "EddieMartinez-module--ButtonWrapper --QGBN8";
export var CardWrapper = "EddieMartinez-module--CardWrapper--vkAh+";
export var CarrouselWrapper2 = "EddieMartinez-module--CarrouselWrapper2--n9OO4";
export var Citations = "EddieMartinez-module--Citations--A0FhB";
export var DescriptionWrapper = "EddieMartinez-module--DescriptionWrapper--zSJKH";
export var ImageWrapper = "EddieMartinez-module--ImageWrapper--iCMCz";
export var LinkWrapper = "EddieMartinez-module--LinkWrapper--DYO1Z";
export var MobileProtrait = "EddieMartinez-module--MobileProtrait--aSmnw";
export var More = "EddieMartinez-module--More--LaLu4";
export var MoreButton = "EddieMartinez-module--MoreButton--w5KoL";
export var NameWrapper = "EddieMartinez-module--NameWrapper--IvSSl";
export var PdpWrapper = "EddieMartinez-module--PdpWrapper--a5B1T";
export var PhotosWrapper = "EddieMartinez-module--PhotosWrapper--fONyj";
export var ProfilWrapper = "EddieMartinez-module--ProfilWrapper--oYeiG";
export var TitleWrapper = "EddieMartinez-module--TitleWrapper--DX0a2";
export var Wrapper = "EddieMartinez-module--Wrapper--x5hLk";